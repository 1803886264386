<template>
  <div class="title">jobly.ai</div>
  <div class="subtitle">Your personal AI job application companion</div>
  <div class="container">

    <div class="configuration-container">
      <h3>What do you need?</h3>

      <div class="option-group">
        <label for="company_research_request-option">Company Research</label>
        <input type="checkbox" id="company_research_request-option" v-model="options.company_research_request" />
      </div>

      <div class="option-group">
        <label for="cover_letter_request-option">Cover Letter</label>
        <input type="checkbox" id="cover_letter_request-option" v-model="options.cover_letter_request" />
      </div>

      <div class="option-group">
        <label for="personal_summary_request-option">Personal Summary</label>
        <input type="checkbox" id="personal_summary_request-option" v-model="options.personal_summary_request" />
      </div>

      <div class="option-group">
        <label for="cv_review_request-option">CV Recommendations</label>
        <input type="checkbox" id="cv_review_request-option" v-model="options.cv_review_request" />
      </div>

      <div class="option-group">
        <label for="role_review_request-option">Role Recommendations</label>
        <input type="checkbox" id="role_review_request-option" v-model="options.role_review_request" />
      </div>

      <div class="option-group">
        <label for="interview_questions_request-option">Interview Questions</label>
        <input type="checkbox" id="interview_questions_request-option" v-model="options.interview_questions_request" />
      </div>
    </div>

    <div class="form-container">
      <input v-model="url_request" id="url_request" class="wide-input" placeholder="Enter the job post URL" />

      <input
        type="text"
        id="full-name"
        class="wide-input"
        v-model="data.name"
        placeholder="Enter your full name"
        :disabled="isDetailsSaved"
      />

      <textarea
        id="plain-text-cv"
        class="textarea-field wide-input"
        v-model="data.cv"
        rows="4"
        placeholder="Enter your plain text CV"
        :disabled="isDetailsSaved"
      ></textarea>

      <button class="submit-btn" @click.prevent="submit">Help Me!</button>
      <button class="helper-btn" @click.prevent="saveDetails">Save Info</button>
      <button class="helper-btn" @click.prevent="deleteDetails">Remove Info</button>
    </div>

  </div>

  <div v-if="data" class="result-container">
      <div v-if="data.research_answer" class="result-group">
        <h2 class="result-title">Company Information</h2>
        <div class="result-content">{{ data.research_answer }}</div>
        <button class="copy-btn" @click="copyToClipboard(data.research_answer)">Copy to Clipboard</button>
      </div>
      <div v-if="data.personal_summary_answer" class="result-group">
        <h2 class="result-title">Your Customized Personal Summary</h2>
        <div class="result-content">{{ data.personal_summary_answer }}</div>
        <button class="copy-btn" @click="copyToClipboard(data.personal_summary_answer)">Copy to Clipboard</button>
      </div>
      <div v-if="data.cover_letter_answer" class="result-group">
        <h2 class="result-title">Your Cover Letter</h2>
        <div class="result-content">{{ data.cover_letter_answer }}</div>
        <button class="copy-btn" @click="copyToClipboard(data.cover_letter_answer)">Copy to Clipboard</button>
      </div>
      <div v-if="data.cv_review_answer" class="result-group">
        <h2 class="result-title">CV Recommendations</h2>
        <div class="result-content">{{ data.cv_review_answer }}</div>
        <button class="copy-btn" @click="copyToClipboard(data.cv_review_answer)">Copy to Clipboard</button>
      </div>
      <div v-if="data.role_recommendations_answer" class="result-group">
        <h2 class="result-title">Role Recommendations</h2>
        <div class="result-content">{{ data.role_recommendations_answer }}</div>
        <button class="copy-btn" @click="copyToClipboard(data.role_recommendations_answer)">Copy to Clipboard</button>
      </div>
      <div v-if="data.interview_qns_answer" class="result-group">
        <h2 class="result-title">Interview Questions</h2>
        <div class="result-content">{{ data.interview_qns_answer }}</div>
        <button class="copy-btn" @click="copyToClipboard(data.interview_qns_answer)">Copy to Clipboard</button>
      </div>
    </div>

    <div v-if="loading" class="overlay">
      <div class="loader"></div>
      <p class="loading-message">Please wait, your AI assistant is generating results...</p>
    </div>

    <div v-if="errorOverlay.isVisible" class="overlay">
    <div class="error-overlay-content" @click="closeErrorOverlay">
      <h2>Hey, something went wrong, let's review</h2>
      <ul class="error-messages">
        <li v-for="message in errorOverlay.messages" :key="message">{{ message }}</li>
      </ul>
      <p class="countdown">I will go away in {{ errorOverlay.countdown }} seconds, or make me by clicking!</p>
    </div>
  </div>


</template>

<style scoped>
.container {
  max-width: 80%;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  display: flex;
}

.title {
  text-align: center;
  font-size: 3em;
  margin-bottom: 5px;
  color: #35495E;
}

.subtitle {
  text-align: center;
  font-size: 1.7em;
  margin-bottom: 10px;
  color: #A8A8A8;
}

/* BEGIN: input formatting */

.form-container {
  background-color: #E5F2FF ;
  border: 2px #D4E8FF solid;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  flex-grow: 1;
}

.configuration-container {
  min-width: 240px;
  background-color: #E5F2FF;
  border-radius: 10px;
  padding: 20px;
  margin-right: 20px;
  height: 200px;
  border: 2px #D4E8FF solid;
}

.wide-input {
  width: 95%;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid #ddd;
  height: 2em;
  margin: 5px;
}

.submit-btn {
  display: inline-block;
  background-color: #a9bad6;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 10px 20px;
  font-size: 2em;
  cursor: pointer;
  min-width: 50%;
  min-height: 40px;
}

.helper-btn {
  display: inline-block;
  background-color: #a9bad6;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  min-width: 15%;
  min-height: 40px;
}

.copy-btn {
  display: inline-block;
  background-color: #a9bad6;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  min-width: 15%;
  min-height: 40px;
}


/* END: input formatting */

.option-group {
  display: flex;
  align-items: center;
  margin-right: 20px;

}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: lighter;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.checkbox-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* BEGIN: results */

.result-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.result-group {
  border: 2px #D4E8FF solid;
  background-color: #E5F2FF;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.result-title {
  font-size: 1.6em;
  margin-bottom: 10px;
}

.result-content {
  margin-bottom: 15px;
  font-size: 1.2em;
}

/* END: results */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    flex-direction: column;
  }

  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #a9bad6;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  .loading-message {
    margin-top: 1em;
    color: #fff;
    font-size: 2em;
    text-align: center;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


.error-overlay-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.countdown {
  font-size: 0.8em;
  font-weight: bolder;
  margin-top:10px;
  margin-bottom: 10px;
}

.error-messages {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.error-messages li {
  color: red;
  margin-bottom: 5px;
}

</style>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      url_request: '',
      options: {
        company_research_request: this.company_research_request,
        cover_letter_request: this.cover_letter_request,
        personal_summary_request: this.personal_summary_request,
        role_review_request: this.role_review_request,
        output_request: true,
        cv_review_request: this.cv_review_request,
        interview_questions_request: this.interview_questions_request,
      },
      data: {
        cv: '',
        name: ''
      },
      isDetailsSaved: false,
      errorOverlay: {
        isVisible: false,
        countdown: 3,
        messages: []
      },
    };
  },
  created() {
    if(localStorage.getItem('joblyai_full_name') && localStorage.getItem('joblyai_plain_text_cv')) {
      this.data.name = localStorage.getItem('joblyai_full_name');
      this.data.cv = localStorage.getItem('joblyai_plain_text_cv');
      this.isDetailsSaved = true;
    }
  },
  methods: {
    validateInput() {
      this.errorOverlay.messages = []; // Clear any existing error messages

      // company research option
      if (this.options.company_research_request && !this.url_request) {
        this.errorOverlay.messages.push('Job URL to do the company research for you.');
      }

      // cover letter option
      if (this.options.cover_letter_request) {
        if (!this.url_request) {
          this.errorOverlay.messages.push('URL, so we know what you will be doing');
        }
        if (!this.data.cv) {
          this.errorOverlay.messages.push('Your plaintext CV, save yourself time by saving it');
        }
        if (!this.data.name) {
          this.errorOverlay.messages.push('Your full name to be entered, or even better - saved');
        }
        if (!this.options.company_research_request) {
          this.errorOverlay.messages.push('Company research option to be enabled');
        }
      }

      // personal summary
      if (this.options.personal_summary_request) {
        if (!this.url_request) {
          this.errorOverlay.messages.push('Advert URL, so we know what you will be doing');
        }
        if (!this.data.cv) {
          this.errorOverlay.messages.push('Your plaintext CV, save yourself time by saving it');
        }
        if (!this.options.company_research_request) {
          this.errorOverlay.messages.push('Company research option to be enabled, we need to know a little more about them');
        }
      }

      // CV review
      if (this.options.cv_review_request) {
        if (!this.data.cv) {
          this.errorOverlay.messages.push('Your plaintext CV, so we can provide the CV review');
        }
      }

      // CV review
      if (this.options.role_review_request) {
        if (!this.data.cv) {
          this.errorOverlay.messages.push('Your lovely CV, without that we cannot provide role recommendations');
        }
      }

      // interview questions
      if (this.options.interview_questions_request) {
        if (!this.url_request) {
          this.errorOverlay.messages.push('A little bit about the company, a job URL...?');
        }
        if (!this.data.cv) {
          this.errorOverlay.messages.push('What you have been doing before - your CV ;)');
        }
        if (!this.options.company_research_request) {
          this.errorOverlay.messages.push('Company research option to be enabled, so we can find out more about them');
        }
      }

      if (this.errorOverlay.messages.length > 0) {
        this.errorOverlay.isVisible = true; // Show the error overlay
        return false;
      }

      return true; // Validation passed
    },
    startCountdown() {
      let countdown = 5;
      const countdownInterval = setInterval(() => {
        countdown--;
        this.errorOverlay.countdown = countdown;
        if (countdown === 0) {
          clearInterval(countdownInterval);
          this.errorOverlay.isVisible = false; // Hide the error overlay
        }
      }, 1000);
    },
    closeErrorOverlay() {
      this.errorOverlay.isVisible = false;
    },
    async submit() {

      if (!this.validateInput()) {
        this.startCountdown(); // Start the countdown for error overlay
        return; // Input validation failed, do not proceed
      }

      this.loading = true; // Show the loading overlay
      this.saveDetails(); // Save details before submitting

      try {
        const response = await axios.post('https://prod-jobly-api.onrender.com/generate', {
          url_request: this.url_request,
          ...this.options,
          ...this.data,
        });
        
        if (response.status === 200) {
          // Update only the keys that you expect from response, instead of overwriting all this.data
          this.data.research_answer = response.data.research_answer;
          this.data.personal_summary_answer = response.data.personal_summary_answer;
          this.data.cover_letter_answer = response.data.cover_letter_answer;
          this.data.cv_review_answer = response.data.cv_review_answer;
          this.data.interview_qns_answer = response.data.interview_qns_answer;
          this.data.role_recommendations_answer = response.data.role_recommendations_answer
          this.startCountdown(); // Start the countdown for error overlay
        } else {
          this.errorOverlay.messages.push(`Error ${response.status}: ${response.data}`);
          this.errorOverlay.isVisible = true;
          this.startCountdown(); // Start the countdown for error overlay
        }

      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a non-200 status code
          this.errorOverlay.messages.push(`Error ${error.response.status}: ${error.response.data}`);
        } else if (error.request) {
          // The request was made but no response was received
          this.errorOverlay.messages.push("We have problems in our basement... keep tight whilst we are working on them");
        } else {
          // Something happened in setting up the request that triggered an error
          this.errorOverlay.messages.push('Oooopsss! Something went wrong.');
        }

        this.errorOverlay.isVisible = true; // Show the error overlay
        this.startCountdown(); // Start the countdown for error overlay
      } finally {
        this.loading = false; // Hide the loading overlay
      }
      
    },
    saveDetails() {
      localStorage.setItem('joblyai_full_name', this.data.name);
      localStorage.setItem('joblyai_plain_text_cv', this.data.cv);
      this.isDetailsSaved = true;
    },
    deleteDetails() {
      localStorage.removeItem('joblyai_full_name');
      localStorage.removeItem('joblyai_plain_text_cv');
      this.data.name = '';
      this.data.cv = '';
      this.isDetailsSaved = false;
      this.$forceUpdate();
    },
    async copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      alert('Copied to clipboard!');
    } catch (err) {
      alert('Failed to copy text to clipboard');
    }
  },
  },
};


</script>